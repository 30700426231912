import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import YouTube from 'react-youtube';

const styles = {
    iframe: {
        width: '720px',
        height: '500px',
        border: 0,
    },
    dialog: {
        overflow: 'hidden',
        width: '500px',
    },
    removeWhitespaceFrame: {
        display: 'inherit',
    }
};

class VideoPopup extends Component {

    render() {
        const {source, classes} = this.props;
        return <Dialog classes={{
            scrollBody: classes.dialog
        }} open={true} maxWidth={false} onClose={this.props.closeDialog.bind(this)}>
            <YouTube opts={{playerVars: {rel: 0}}} containerClassName={classes.removeWhitespaceFrame}
                     onEnd={this.props.onVideoEnd} className={classes.iframe}
                     videoId={new URL(source).searchParams.get('v')}/>
        </Dialog>
    }
}

export default withStyles(styles)(VideoPopup);
