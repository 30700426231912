import React, {Component} from 'react';
import {Children} from "../component/Children";
import Typography from "@material-ui/core/Typography/Typography";
import {withStyles} from '@material-ui/core/styles';
import lang from "../../json/lang";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Grid from "@material-ui/core/Grid";
import placeholder from '../../img/placeholder.png';
import VideoPopup from "./VideoPopup";

const ALPHABET = String.fromCharCode(...Array(123).keys()).slice(97).toUpperCase().split('');

const styles = theme => ({
    informationContent: {
        padding: "90px 60px 0 60px",
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            padding: "25px 20px 0 20px",
            height: 'calc(100vh - 88px)'
        }
    },
    dialog: {},
    mediaImg: {
        width: '100%',
        height: 650,
        objectFit: 'cover'
    },
    questionNumber: {
        color: '#EE7203',
        fontFamily: "futura-pt, sans-serif",
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: 0.44,
        paddingBottom: 20,
        display: 'block',
        textTransform: 'uppercase',
    },
    answerOption: {
        color: '#565656',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '1em',
    },
    answerOptionContainer: {
        paddingBottom: 35,
    },
    questionTitle: {
        color: '#565656',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontSize: '2.1em',
        lineHeight: 1.6,
        [theme.breakpoints.down('md')]: {
            fontSize: '1.8em',
        }
    },
    questionDescription: {
        color: '#565656',
        display: 'block',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '1em',
        padding: '20px 0 40px 0',
        "& p": {
            paddingBottom: 40,
        },
        [theme.breakpoints.down('md')]: {
            padding: '20px 0 125px 0',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 0 165px 0',
        }
    },
    customRadio: {
        "& span .MuiIconButton-label": {
            color: '#EE7203',
            "& .MuiSvgIcon-root": {
                fontSize: '1.5em',

            }
        },
        "& span .MuiIconButton-colorSecondary": {
            "&:hover": {
                color: '#EE7203',
            }
        },
    },
    videoMediaMobile: {
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        cursor: 'pointer',
        overflow: 'hidden',
        "& img": {
            display: 'block',
            transition: 'transform 500ms'
        },
        "&:hover": {
            "& img": {
                transform: 'scale(1.05)',
                transformOrigin: '50% 50%',
            },
            "& svg": {
                fillOpacity: .85,
            },
        },
        "& svg": {
            fill: '#EE7203',
            position: 'absolute',
            margin: 'auto',
            textAlign: 'center',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            transition: '175ms'
        },
        height: 350,
        marginBottom: 20,
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    videoMedia: {
        position: 'relative',
        display: 'block',
        width: '100%',
        cursor: 'pointer',
        overflow: 'hidden',
        "& img": {
            display: 'block',
            transition: 'transform 500ms'
        },
        "&:hover": {
            "& img": {
                transform: 'scale(1.05)',
                transformOrigin: '50% 50%',
            },
            "& svg": {
                fillOpacity: .85,
            },
        },
        "& svg": {
            fill: '#EE7203',
            position: 'absolute',
            margin: 'auto',
            textAlign: 'center',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            transition: '175ms'
        }
    },
    desktopImg: {
        width: '100%',
        objectFit: 'cover',
        display: 'block',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    mediaMobileImg: {
        objectFit: 'scale-down',
        display: 'block',
        maxHeight: 350,
        marginBottom: 20,
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    }
});

class Question extends Component {

    state = {
        showPopupVideo: false
    };

    getPopupVideo() {
        this.setState({
            showPopupVideo: true
        })
    }

    closePopupVideo() {
        this.setState({
            showPopupVideo: false
        })
    }

    render() {
        const {questions, index, classes} = this.props;
        const question = questions[index];
        return <Children className={this.props.className}>

            {this.state.showPopupVideo ?
                <VideoPopup closeDialog={this.closePopupVideo.bind(this)} source={question.media.video}/> : null}

            <Children>
                <Grid className={classes.gridRoot} spacing={0} container>
                    <Grid item xs={12} lg={6}>
                        <Children className={classes.informationContent}>
                            <Typography className={classes.questionNumber} variant="body1">
                                {lang.question} {index + 1}
                            </Typography>

                            <Typography variant="h4" className={classes.questionTitle}>
                                {question.title}
                            </Typography>

                            <Children className={classes.questionDescription}>
                                <Typography variant="body1">
                                    {question.description}
                                </Typography>

                                {question.media.video ?
                                    <iframe className={classes.videoMediaMobile}
                                            title="Instructie video"
                                            src={'https://www.youtube.com/embed/' + new URL(question.media.video).searchParams.get('v') + '?autoplay=1&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0'}/>
                                    :
                                    <img className={classes.mediaMobileImg} src={question.media.url ? question.media.url : placeholder} alt="Explaining"/>
                                }

                                {question.type === 'radio' ?
                                    <FormControl component="fieldset">
                                        <RadioGroup value={this.props.selRadio > -1 ? this.props.selRadio : ''}
                                                    onChange={this.props.broadcastAnswer.bind(this)}>
                                            {question.option.map((questionItem, index) => <Children
                                                    key={index}
                                                    className={classes.answerOptionContainer}><FormControlLabel
                                                    key={index}
                                                    value={index}
                                                    classes={{
                                                        label: classes.answerOption,
                                                        root: classes.customRadio
                                                    }}
                                                    control={<Radio/>}
                                                    label={ALPHABET[index] + ' - ' + questionItem.value}/>
                                                </Children>
                                            )}
                                        </RadioGroup>
                                    </FormControl>
                                    : null}
                            </Children>

                        </Children>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        {question.media.video ?
                            <Children className={classes.videoMedia} onClick={this.getPopupVideo.bind(this)}>
                                <img className={classes.mediaImg}
                                     src={"https://img.youtube.com/vi/" + new URL(question.media.video).searchParams.get('v') + "/sddefault.jpg"}
                                     alt="Explaining video"/>
                                <svg height="8em" viewBox="0 0 32 32"
                                     width="8em"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="play_x5F_alt">
                                        <path
                                            d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M10,24V8l16.008,8L10,24z"
                                        />
                                    </g>
                                </svg>
                            </Children>
                            :
                            <img className={[classes.mediaImg, classes.desktopImg].join(', ')} src={question.media.url ? question.media.url : placeholder}
                                 alt="Explaining"/>
                        }
                    </Grid>
                </Grid>
            </Children>
        </Children>
    }
}

export default withStyles(styles)(Question);
