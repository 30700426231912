import React, {Component} from 'react';
import {Children} from "../component/Children";
import Typography from "@material-ui/core/Typography/Typography";
import {withStyles} from '@material-ui/core/styles';
import lang from "../../json/lang";
import Grid from "@material-ui/core/Grid";
import introduction from '../../img/introduction.png';
import logo from '../../img/logo.png';

const styles = theme => ({
    informationContent: {
        padding: "30px 60px 0 60px",
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            padding: "25px 20px 0 20px",
            height: 'calc(100vh - 88px)'
        }
    },
    dialog: {},
    mediaImg: {
        width: '100%',
        maxHeight: 650,
        objectFit: 'cover',
        overflow: 'hidden',
        display: 'block',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    endPhaseTitle: {
        color: '#565656',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        fontSize: '2.1em',
        lineHeight: 1.6,
        [theme.breakpoints.down('md')]: {
            fontSize: '1.8em',
        }
    },
    endPhaseDescription: {
        display: 'block',
        color: '#565656',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: '1em',
        padding: '20px 0 40px 0',
        [theme.breakpoints.down('md')]: {
            padding: '20px 0 125px 0',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 0 165px 0',
        }
    },
    footer: {
        backgroundColor: '#EE7203',
        paddingLeft: 60,
        height: 88,
        [theme.breakpoints.down('md')]: {
            padding: 0,
            textAlign: 'center',
            position: 'fixed',
            bottom: 0,
            width: '100%'
        }
    },
    nextQuestionBtnContainer: {
        textAlign: 'center',
    },
    nextQuestionBtn: {
        borderRadius: 23,
        margin: '20px 0',
        fontFamily: "futura-pt, sans-serif",
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: 0.35,
        padding: '10px 20px',
        color: '#EE7203',
        backgroundColor: '#F9DE09',
        '&:disabled': {
            color: '#FFFFFF',
            backgroundColor: '#EE7203',
        }
    },
    logo: {
        width: 254,
        paddingBottom: 50,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            display: 'block',
            margin: 'auto',
            width: 300
        }
    }
});

class EndPhase extends Component {

    componentDidMount() {
        fetch(`/api/session`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({id: this.props.session})
        });
    }

    render() {
        const {classes} = this.props;
        return <Children className={this.props.className}>
            <Children>
                <Grid container spacing={0}>
                    <Grid item xs={12} lg={6}>
                        <Children className={classes.informationContent}>

                            <img style={{width: 224, paddingBottom: 50}} src={logo} className={classes.logo}
                                 alt="Inrush logo"/>

                            <Typography variant="h4" className={classes.endPhaseTitle}>
                                {lang.endPhase.title}
                            </Typography>
                            <Typography variant="body1" className={classes.endPhaseDescription}>
                                {lang.endPhase.description}
                            </Typography>

                        </Children>
                    </Grid>
                    <Grid item xs={6}>
                        <img className={classes.mediaImg} src={introduction} alt="Introductie plaatje"/>
                    </Grid>
                </Grid>
            </Children>
            <Children className={classes.footer}/>
        </Children>
    }
}

export default withStyles(styles)(EndPhase);
