import React, {Component} from 'react';
import {Children} from "../component/Children";
import Typography from "@material-ui/core/Typography/Typography";
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import logo from '../../img/logo.png';
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import FormControl from "@material-ui/core/FormControl/FormControl";
import FilledInput from "@material-ui/core/FilledInput/FilledInput";

const styles = theme => ({
    informationContent: {
        padding: "30px 60px 0 60px",
        overflow: 'auto',
        [theme.breakpoints.down('md')]: {
            padding: "25px 20px 0 20px",
            height: 'calc(100vh - 88px)'
        },
        marginBottom: '3em',
    },
    dialog: {},
    mediaImg: {
        width: '100%',
        maxHeight: 650,
        objectFit: 'cover',
        overflow: 'hidden',
        display: 'block',
    },
    logo: {
        width: 254,
        paddingBottom: 50,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            display: 'block',
            margin: 'auto',
            width: 300
        }
    },
    introductionTitle: {
        color: '#565656',
        fontFamily: "'Open Sans', sans-serif",
        fontWeight: 600,
        lineHeight: 1.6,
        fontSize: '2.1em',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.8em',
        }
    },
    introductionDescriptionTop: {
        color: '#565656',
        display: 'block',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 16,
        padding: '20px 0 10px 0',
        lineHeight: 2,
        [theme.breakpoints.down('md')]: {
            padding: '20px 0 10px 0',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 0 10px 0',
        }
    },
    introductionDescription: {
        color: '#565656',
        display: 'block',
        fontFamily: "'Open Sans', sans-serif",
        fontSize: 16,
        padding: '20px 0 40px 0',
        lineHeight: 2,
        [theme.breakpoints.down('md')]: {
            padding: '20px 0 40px 0',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 0 40px 0',
        }
    },
    footer: {
        backgroundColor: '#EE7203',
        paddingLeft: 60,
        [theme.breakpoints.down('md')]: {
            padding: 0,
            textAlign: 'center',
            position: 'fixed',
            bottom: 0,
            width: '100%'
        }
    },
    nextQuestionBtnContainer: {
        textAlign: 'center',
    },
    nextQuestionBtn: {
        borderRadius: 23,
        margin: '20px 0',
        fontFamily: "futura-pt, sans-serif",
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
        fontWeight: 500,
        letterSpacing: 0.35,
        padding: '10px 20px',
        color: '#FFFFFF',
        backgroundColor: '#565656',
        border: '2px solid transparent',
        "& svg g": {
            fill: '#c2c2c2',
        },
        '&:disabled': {
            color: '#c2c2c2',
            backgroundColor: '#F1F3F5',
        },
        '&:hover': {
            backgroundColor: '#F1F3F5',
            border: '2px solid #565656',
            color: '#565656',
            fill: '#ffffff',
            "& svg g": {
                fill: '#565656',
            }
        }
    },
    imageContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    desktopImg: {
        width: '100%',
        objectFit: 'cover',
        display: 'block',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    mediaMobileImg: {
        objectFit: 'scale-down',
        display: 'block',
        maxHeight: 350,
        marginBottom: 20,
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        }
    },
    buttonArrow: {
        marginLeft: 10,
    },
    spacingForm: {
    }
});

class Login extends Component {

    state = {
        email: '',
    };

    handleInput(event) {
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    login() {
        fetch(`/api/session`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({email: this.state.email})
        }).then(res => res.json()).then(data => {
            if (data.error) {
                alert("Email is niet geldig.");
                this.setState({
                    email: '',
                })
            } else if (data.session) {
                window.history.pushState(null, null, "?session="+data.session);
                this.props.dispatch(data.session);
            }
        });
    }

    render() {
        const {classes} = this.props;
        return <Children className={this.props.className}>
            <Children>
                <Grid container spacing={0}>
                    <Grid item xs={12} lg={6}>
                        <Children className={classes.informationContent}>

                            <img className={classes.logo} src={logo} alt="Inrush logo"/>

                            <Typography variant="h4" className={classes.introductionTitle}>
                                Welkom bij Inrush poortinstructie
                            </Typography>

                            <Typography variant="body1" className={classes.introductionDescriptionTop}>Laat een e-mailadres achter waar u toegang toe heeft:</Typography>

                            <Typography variant="body2" className={classes.introductionDescription}>We gebruiken dit mailadres om een u een bevestiging te sturen van uw deelname.</Typography>


                            <FormControl fullWidth className={classes.spacingForm} variant="filled">

                                <InputLabel htmlFor="input-login-email">E-mail:</InputLabel>
                                <FilledInput
                                    name="email"
                                    id="input-login-email"
                                    onChange={this.handleInput.bind(this)}
                                    value={this.state.email}
                                    placeholder="voorbeeld@uwmailadres.nl"
                                    startAdornment={<InputAdornment
                                        position="start"><AlternateEmailIcon/></InputAdornment>}
                                />
                            </FormControl>
                        </Children>
                    </Grid>
                    <Grid className={classes.imageContainer} item xs={12} lg={6}>

                    </Grid>
                </Grid>
            </Children>
            <Children className={classes.footer}>
                <Button disabled={!/[^@]+@[^@]+\.[a-zA-Z]{2,6}/.test(this.state.email)}
                        className={classes.nextQuestionBtn}
                        onClick={this.login.bind(this)}>{"Inloggen"}
                    <svg width="16px" height="12px" className={classes.buttonArrow} viewBox="0 0 16 12" version="1.1"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="Prototype" stroke="none" strokeWidth="1" fillRule="evenodd">
                            <g id="Start" transform="translate(-390.000000, -770.000000)" fill="#FFFFFF"
                               fillRule="nonzero">
                                <g id="Primary-btn-/-yellow-icon" transform="translate(139.000000, 754.000000)">
                                    <g id="Stacked-Group" transform="translate(32.000000, 12.000000)">
                                        <path
                                            d="M234.924707,9.59569706 C234.874126,9.47163304 234.7993,9.35899164 234.704642,9.26441797 L229.763181,4.29523152 C229.370943,3.90158948 228.735,3.90158949 228.342761,4.29523154 C227.950523,4.68887359 227.950523,5.32709345 228.342761,5.72073551 L231.593722,8.97329391 L220.000296,8.97329391 C219.447848,8.97329391 219,9.42274453 219,9.97716996 C219,10.5315954 219.447848,10.981046 220.000296,10.981046 L231.593722,10.981046 L228.342761,14.2336044 C228.057301,14.4789416 227.932958,14.8641574 228.020856,15.2308739 C228.108754,15.5975904 228.394057,15.8839147 228.759466,15.9721271 C229.124874,16.0603395 229.508716,15.9355519 229.753178,15.6490696 L234.704642,10.6798832 C234.991932,10.3934698 235.078812,9.96147954 234.924707,9.5856583 L234.924707,9.59569706 Z"
                                            id="Path"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </Button>
            </Children>
        </Children>
    }
}

export default withStyles(styles)(Login);
