import React, {Component} from 'react';
import {Children} from "./component/Children";
import questions from "../json/questions";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";
import {withStyles} from '@material-ui/core/styles';
import Question from "./component/Question";
import Button from "@material-ui/core/Button";
import lang from "../json/lang";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Introduction from "./component/Introduction";
import EndPhase from "./component/EndPhase";
import CorrectSVG from "../img/correct.svg";
import IncorrectSVG from "../img/wrong.svg";
import Login from "./component/Login";

const theme = createMuiTheme();

const styles = {
    application: {
        backgroundColor: '#ffffff',
        margin: '9em auto 0 auto',
        minWidth: '300px',
        maxWidth: '1300px',
        [theme.breakpoints.down('md')]: {
            margin: 0,
            width: '100%',
        }
    },
    footer: {
        backgroundColor: '#EE7203',
        [theme.breakpoints.down('md')]: {
            padding: 0,
            textAlign: 'center',
            position: 'fixed',
            bottom: 0,
            width: '100%'
        }
    },
    progress: {
        backgroundColor: '#F1F3F5',
        border: '10px',
        width: '100%',
        verticalAlign: 'center',
        height: 10,
        borderRadius: 5,
        '& div': {
            backgroundColor: '#393939',
            borderRadius: 5,
        },
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    },
    nextQuestionBtnContainer: {
        textAlign: 'center',
    },
    nextQuestionBtn: {
        borderRadius: 32,
        margin: '20px 0',
        fontFamily: "futura-pt, sans-serif",
        fontSize: '16px',
        letter: .4,
        fontWeight: 600,
        letterSpacing: 0.35,
        padding: '13px 21px',
        color: '#FFFFFF',
        border: '2px solid transparent',
        backgroundColor: '#565656',
        textTransform: 'uppercase',
        '&:disabled': {
            color: '#c2c2c2',
            backgroundColor: '#F1F3F5',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
        '&:hover': {
            backgroundColor: '#F1F3F5',
            border: '2px solid #565656',
            color: '#565656',
            fill: '#ffffff',
            "& svg g": {
                fill: '#565656',
            }
        }
    },
    progressText: {
        color: '#ffffff',
        fontFamily: "futura-pt, sans-serif",
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.31,
        paddingLeft: 10,
    },
    questionOutOf: {
        color: '#FFFFFF',
    },
    dialog: {
        textAlign: 'center',
    },
    dialogArrow: {
        marginLeft: 10,
    },
    dialogBackgroundCorrect: {
        backgroundColor: '#EE7203',
        color: '#ffffff',
        padding: 20,
        
    },
    dialogRoot: {
        "& .MuiBackdrop-root": {
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
        }
    },
    dialogBackgroundIncorrect: {
        backgroundColor: '#EE7203',
        color: '#ffffff',
        padding: 20,
    },
    dialogIcon: {
        marginTop: 10,
        maxHeight: '65px',
    },
    dialogButton: {
        borderRadius: 23,
        border: '2px solid transparent',
        textAlign: 'center',
        fontFamily: "futura-pt, sans-serif",
        fontSize: '1.1em',
        fontWeight: 500,
        letterSpacing: 0.35,
        padding: '7px 40px',
        textTransform: 'uppercase',
        '&:hover': {
            backgroundColor: '#F1F3F5',
            border: '2px solid #565656',
            color: '#565656',
            fill: '#ffffff',
            "& svg g": {
                fill: '#565656',
            }
        }
    },
    dialogButtonCorrect: {
        color: '#FFFFFF',
        backgroundColor: '#565656',
        textTransform: 'uppercase',
    },
    dialogButtonIncorrect: {
        color: '#FFFFFF',
        backgroundColor: '#565656',
        textTransform: 'uppercase',
    },
    emptyGridFooter: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
};

class App extends Component {

    state = {
        numQuestion: 0,
        selRadio: NaN,
        dialog: undefined,
        correct: undefined,
        introductionPhase: true,
        session: null,
    };

    //TODO check must be done by DB
    checkQuestion() {
        this.setState({
            dialog: !!questions[this.state.numQuestion].option[this.state.selRadio].correct,
            correct: !!questions[this.state.numQuestion].option[this.state.selRadio].correct
        });

        if (!!questions[this.state.numQuestion].option[this.state.selRadio].correct) {
            fetch(`/api/session`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
                body: JSON.stringify({
                    question: this.state.numQuestion + 1,
                    id: this.state.session
                })
            }).then(res => res.json()).then(data => {
                if (data.error) {
                    alert("Something went wrong... Please try again later.");
                }
            });
        }
    }

    updateQuestionIndex() {
        this.setState({
            updateQuestionIndex: ++this.state.numQuestion,
            selRadio: NaN,
        })
    }

    broadcastAnswer(event) {
        const sel = parseInt(event.target.value);
        this.setState({
            selRadio: sel,
        });
    }

    closeDialog() {
        this.setState({
            dialog: undefined,
        });

        setTimeout(_ => {
            if (this.state.correct) {
                this.updateQuestionIndex();
            }
        }, 250);
    }

    navigateToQuestions() {
        fetch(`/api/session/intro-finished`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify({
                id: this.state.session
            })
        }).then(res => res.json()).then(data => {
            if (data.error) {
                alert("Something went wong... Please try again later.");
            }
        });

        this.setState({
            introductionPhase: false
        })
    }

    loginDispatch(id) {
        this.setState({
            session: id
        });
    }

    componentWillMount() {
        const session = new URLSearchParams(window.location.search).get('session');
        if (session) {
            fetch(`/api/session/` + session, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                mode: 'cors',
            }).then(res => res.json()).then(data => {
                if (!data.error) {
                    this.setState({
                        session: session,
                        numQuestion: data.session.num_question
                    });
                    if (data.session.intro_completed) {
                        this.navigateToQuestions();
                    }
                } else {
                    window.history.pushState(null, null, window.location.pathname);
                }
            });
        }
    }

    render() {

        const {numQuestion, introductionPhase} = this.state;
        const {classes} = this.props;

        return <Children className={classes.application}>

            {questions[this.state.numQuestion] ? <Dialog classes={{
                root: classes.dialogRoot,
                paper: this.state.correct ? classes.dialogBackgroundCorrect : classes.dialogBackgroundIncorrect
            }}
                                                         className={classes.dialog}
                                                         open={this.state.dialog !== undefined}>
                {this.state.correct ? <img className={classes.dialogIcon} src={CorrectSVG} alt="Correct"/> :
                    <img className={classes.dialogIcon} src={IncorrectSVG} alt="Incorrect"/>}
                <DialogTitle>{this.state.correct ? lang.answer_correct : lang.answer_wrong}</DialogTitle>

                <DialogContent>
                    <DialogContentText style={{
                        color: '#FFFFFF',
                        fontFamily: "'Open Sans', sans-serif",
                    }}>
                        {this.state.correct ? questions[this.state.numQuestion].answerDescription : "Probeer het opnieuw"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{display: 'block'}}>
                    <Button onClick={this.closeDialog.bind(this)}
                            className={[classes.dialogButton, this.state.correct ? classes.dialogButtonCorrect : classes.dialogButtonIncorrect].join(' ')}
                    >
                        {this.state.correct ? <>{lang.dialog_button_correct}
                            <svg width="16px" height="12px" className={classes.dialogArrow} viewBox="0 0 16 12"
                                 version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="Prototype" stroke="none" strokeWidth="1" fillRule="evenodd">
                                    <g id="Start" transform="translate(-390.000000, -770.000000)" fill="#FFFFFF"
                                       fillRule="nonzero">
                                        <g id="Primary-btn-/-yellow-icon" transform="translate(139.000000, 754.000000)">
                                            <g id="Stacked-Group" transform="translate(32.000000, 12.000000)">
                                                <path
                                                    d="M234.924707,9.59569706 C234.874126,9.47163304 234.7993,9.35899164 234.704642,9.26441797 L229.763181,4.29523152 C229.370943,3.90158948 228.735,3.90158949 228.342761,4.29523154 C227.950523,4.68887359 227.950523,5.32709345 228.342761,5.72073551 L231.593722,8.97329391 L220.000296,8.97329391 C219.447848,8.97329391 219,9.42274453 219,9.97716996 C219,10.5315954 219.447848,10.981046 220.000296,10.981046 L231.593722,10.981046 L228.342761,14.2336044 C228.057301,14.4789416 227.932958,14.8641574 228.020856,15.2308739 C228.108754,15.5975904 228.394057,15.8839147 228.759466,15.9721271 C229.124874,16.0603395 229.508716,15.9355519 229.753178,15.6490696 L234.704642,10.6798832 C234.991932,10.3934698 235.078812,9.96147954 234.924707,9.5856583 L234.924707,9.59569706 Z"
                                                    id="Path"/>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </> : lang.dialog_button_incorrect}
                    </Button>
                </DialogActions>
            </Dialog> : null}

            <ThemeProvider theme={theme}>
                {this.state.session ?
                    introductionPhase ? <Introduction
                        navigateToQuestions={this.navigateToQuestions.bind(this)}/> : questions[this.state.numQuestion] ? <>
                        <Question
                            index={numQuestion}
                            questions={questions}
                            broadcastAnswer={this.broadcastAnswer.bind(this)}
                            selRadio={this.state.selRadio}
                        />
                        <Children className={classes.footer}>

                            <Grid alignItems='center' container>
                                <Grid item xs={3} className={classes.emptyGridFooter}>
                                </Grid>
                                <Grid item xs={2} lg={6}>
                                    <Grid alignItems='center' container>
                                        <Grid item xs={10}>
                                            <LinearProgress variant="determinate" className={classes.progress}
                                                            value={100 / questions.length * numQuestion}/>
                                        </Grid>
                                        <Grid className={classes.progressText} item xs={12} lg={2}>
                                            {numQuestion + 1} / <span
                                            className={classes.questionOutOf}>{questions.length}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.nextQuestionBtnContainer} xs={10} lg={3}>
                                    <Button className={classes.nextQuestionBtn}
                                            disabled={isNaN(this.state.selRadio)}
                                            onClick={this.checkQuestion.bind(this)}>{lang.button_check}</Button>
                                </Grid>
                            </Grid>
                        </Children></> : <EndPhase session={this.state.session}/> :
                    <Login dispatch={this.loginDispatch.bind(this)}/>
                }
            </ThemeProvider>
        </Children>
    }
}

export default withStyles(styles)(App);
